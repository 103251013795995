import React, { useState, useEffect, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./selectedTable.css";
import useSelectedTableController from "./selectedTableController";
import { FiTrash } from "react-icons/fi";
import { StoreContext } from "../../stores/StoreContext";
import ToasterNotification from "../toasterNotification/ToasterNotification.jsx";
import { AiOutlineSave } from "react-icons/ai";
const SelectedTable = (props) => {
  const { activated, inReviewState } = props;
  const { checkedList, tableRefference, canReview } = useContext(StoreContext);
  //pass props to controller


  const tableRef = tableRefference;
  const {
    handleDelete,
    checkedCount,
    handleReview,
    handleSend,
    showToast,
    toastMessage,
    toastType,
    sending,

  } = useSelectedTableController();

  return (
    <>
      <div id="title2-detail-table">
        <h5>Total by SSL</h5>
      </div>
      <div className="table-responsive-second-detail-table">
        <table className="table table-borderless custom-table-second-detail-table ">
          <thead id="table-headers-second-detail-table">
            <tr>
              <th>SCAC</th>
              <th>SSL</th>
              <th>S/T</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody id="table-second-body-detail-table">
            {checkedList.map((item, index) => (
              <tr key={index}>
                <td>{item.scac}</td>
                <td>{item.ssl}</td>
                <td>{item.size}</td>
                <td>{item.total}</td>
                <td>
                  <FiTrash
                    id="deleteIcon"
                    onClick={() => handleDelete(tableRef, index, inReviewState)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr id="line-detail-table" />
      <div id="total-detail-table">
        <p>Total:</p>
        <p id="totalNumber-detail-table">{checkedCount}</p>
      </div>
      <div
        id="buttonSpace-detail-table"
        style={{ display: "grid", justifyContent: "flex-end" }}
      >
        {inReviewState ? (
          <button
            type="button"
            className={`btn btn-success ${sending === true ? 'disabled' : ''}`}
            id="sendBtn-detail-table"
            onClick={() => {
              handleSend();
            }}
          >
            {sending ? <> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>   Loading...</> : <>
         
              Send</>
            }

          </button>
        ) : (
          <button
            type="button"
            className={`btn btn-success ${canReview === false ? 'disabled' : ''}`}
            id="button-detail-table"
            onClick={() => {
              handleReview();
              activated();
            }}
          >
            Review Request
          </button>
        )}
      </div>
      <ToasterNotification
        showToast={showToast}
        toastMessage={toastMessage}
        toastType={toastType}
      />
    </>
  );
};
export default SelectedTable;
