import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FieldsRow from "./components/fieldRow/fieldsRow";
import OptionsTableModal from "./components/optionTableModal/optionsTableModal";
import AvailableTable from "./components/availableTable/availableTable";
import SelectedTable from "./components/selectedTable/selectedTable";
import { StoreProvider } from "./stores/StoreContext";

export default function Root(props) {
  const [inReview, setInReview] = useState(false);
  const handleChangeReview = () => {
    setInReview(true);
  };
  const handleReturn = () => {
    setInReview(false);
  };
  return (
    <>
      <React.StrictMode>
        <StoreProvider>
          <div className="App-detail-table">
            <div id="container-fluid-detail-table">
              <div className="col-12 fieldContainer-detail-table">
                <FieldsRow activated={inReview} onReturnClick={handleReturn} />
              </div>
              <div className="col-12">
                <div className="row">
                  <div
                    className="col-xl-9 col-lg-12  pb-lg-1 col-md-12 col-sm-12"
                    id="firstContainer"
                  >
                    <section id="firstTable-detail-table">
                      <AvailableTable inReviewState={inReview} />
                    </section>
                  </div>

                  <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 secondContainer">
                    <section id="secondTable-detail-table">
                      <SelectedTable
                        activated={handleChangeReview}
                        inReviewState={inReview}
                      />
                    </section>
                  </div>
                  <OptionsTableModal />
                </div>
              </div>
            </div>
          </div>
        </StoreProvider>
      </React.StrictMode>
    </>
  );
}
