import { convertUtcToMMDD,convertToMMDD } from "./utils";

const DEFAULT_VALUE = "N/A";

class CargoModel {
  constructor(data) {
    this.id = data?.id || DEFAULT_VALUE;
    this.scac = data?.scac || DEFAULT_VALUE;
    this.ssl = data?.ssl || DEFAULT_VALUE;
    this.size = data?.size || DEFAULT_VALUE;
    this.containerNumber = data?.containerNumber || DEFAULT_VALUE;
    this.chassisNumber = data?.chassisNumber || DEFAULT_VALUE;
    this.lastFreeDay = data?.lastFreeDay
      ? convertToMMDD(data.lastFreeDay)
      : DEFAULT_VALUE;
    this.status = data?.status || DEFAULT_VALUE;
    this.arrival = data?.arrival
      ? convertUtcToMMDD(data.arrival)
      : DEFAULT_VALUE;
    this.location = data?.location || DEFAULT_VALUE;
    this.spot = data?.spot || 0;
  }
}

export default CargoModel;
