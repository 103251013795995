import React, { useState, useEffect, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { FiEdit } from "react-icons/fi";
import "./availableTable.css";
import useAvailableTableController from "./availableTableController";
import { StoreContext } from "../../stores/StoreContext";
const AvailableTable = (props) => {
  const { inReviewState } = props;
  const tableRef = useRef();
  const { filteredData, handleFillShownModal, handleFillSslSelected } =
    useContext(StoreContext);
  let [tableData, setTableData] = useState(null || []);
  const {
    handleSetTableRefference,
    handleAllPrev,
    handleCheck,
    handleReturn,
    handleTableUpdate,
    handleSortColumn,
    show,
    fetchCargoData,
  } = useAvailableTableController();

  useEffect(() => {
    const interval = setInterval(() => {
      if (!inReviewState) {
        fetchCargoData();
      }
    }, 60000);
    return () => clearInterval(interval); // Clean up the interval on component unmount
  });
  useEffect(() => {
    if (!inReviewState) {
      handleReturn(tableRef);
    }
    handleSetTableRefference(tableRef);
  }, [tableRef, inReviewState]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sslName = urlParams.get("sslScac") === "default" ? "N/A" : urlParams.get("sslScac");
    handleFillSslSelected(sslName);
  }, [tableRef]);

  useEffect(() => {
    setTableData(filteredData);
  }, [filteredData]);

  useEffect(() => {
    handleTableUpdate(tableRef, tableData);
  }, [tableData]);

  return (
    <>
      <h5 id="title-detail-table" className={inReviewState ? "bg-primary text-white" : ""}>
        {inReviewState ? "Selected Review Empties for ST" : "Available Empties"}
      </h5>
      {show ? (
        <div class="d-flex justify-content-center" style={{ minHeight: '70vh' }}>
          <div class="spinner-border" role="status" style={{ alignSelf: 'center', scale: '5', color: '#22224f' }}>
          </div>
        </div>


      ) : (
        <div className="table-responsive-detail-table">
          <table
            className="table table-hover custom-table-detail-table table-sm table-striped"
            ref={tableRef}
          >
            <thead id="table-headers-detail-table">
              <tr>
                <th id="checkAllCell-detail-table">
                  {inReviewState ? (
                    ""
                  ) : (
                    <input
                      className="form-check-input mx-auto"
                      type="checkbox"
                      value=""
                      id="checkAll-detail-table"
                      onChange={(e) => handleAllPrev(tableRef, e.target.checked)}
                    />
                  )}
                </th>
                <th>SCAC</th>
                <th onClick={(e) => handleSortColumn()} id="sortColumn-detail-table" style={{ cursor: 'pointer' }}>SSL</th>
                <th>S/T</th>
                <th>Cont/Trl Num</th>
                <th>Chassis N</th>
                <th>LFD</th>
                <th>Status</th>
                <th>Arrival</th>
                <th>Location</th>
                <th style={{ paddingRight: "25px" }}>Spot</th>
                {/* <th style={{ paddingRight: "25px" }}>Options</th> */}
              </tr>
            </thead>
            {filteredData === null ? (
              <p>Loading...</p>
            ) : (
              <tbody id="tbody-style-detail-table">
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>

                      {item.status !== "External" ? <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={`check${index}`}
                        style={{
                          marginLeft: "20px",
                        }}
                        onChange={(e) =>
                          handleCheck(
                            e.target.checked,
                            item,
                            inReviewState,
                            tableRef
                          )
                        }
                      /> : null}

                    </td>
                    <td>{item.scac}</td>
                    <td>{item.ssl}</td>
                    <td>{item.size}</td>
                    <td>{item.containerNumber}</td>
                    <td>{item.chassisNumber}</td>
                    <td>{item.lastFreeDay}</td>
                    <td>{item.status}</td>
                    <td>{item.arrival}</td>
                    <td>{item.location}</td>
                    <td style={{
                      paddingRight: "20px",
                    }}>{item.spot}</td>
                    {/* <td>
                    {" "}
                    <FiEdit
                      id="optionIcon"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                      onClick={() => handleFillShownModal(true, item)}
                    />
                  </td> */}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>)}
    </>
  );
};
export default AvailableTable;
