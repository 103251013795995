import React, { useContext, useEffect, useState } from "react";
import useAvailableTableController from "../availableTable/availableTableController";
import { StoreContext } from "../../stores/StoreContext";

const useFieldRowController = () => {
  const {
    handleFillLfdDate,
  } = useContext(StoreContext);

  const {
    handleFilterTable
  } = useAvailableTableController();
  const setLFDInputToToday = () => {
    setTimeout(() => {
      const today = new Date();
      const inputDate = document.getElementById("dateInput");
      if (inputDate) {
        // Establecer el valor del input
        inputDate.value = today.toISOString().substr(0, 10);
        handleFillLfdDate(today);
      }
    }, 100);
  };

  const renderOption = (list, selected) => {
    return list.map((element, index) => (
      <option key={index} value={element || ""} selected={element === selected}>
        {element || ""}
      </option>
    ));
  };

  return {
    renderOption,
    setLFDInputToToday,
    handleFilterTable,
  };
};
export default useFieldRowController;
