import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./fieldsRow.css";
import { FaGlobe } from "react-icons/fa";
import { BsFillCaretLeftFill } from "react-icons/bs";
import { StoreContext } from "../../stores/StoreContext";
import useFieldRowController from "./fieldRowController";

import "react-datepicker/dist/react-datepicker.css";

const FieldsRow = (props) => {
  const { activated, onReturnClick } = props;

  const {
    handleFillLfdDate,
    scacList,
    stList,
    handleFillScacSelected,
    handleFillStSelected,
    sslSelected,
    scacSelected,
    stSelected,
    sslList,
    handleFillSslSelected,
    setContainerFiltered,
    containerFiltered,
    lfdDateSelected,
    statusList,
    setStatusSelected,
    statusSelected
  } = useContext(StoreContext);
  const { setLFDInputToToday, handleFilterTable, renderOption } = useFieldRowController();

  const handleReturnClick = () => {
    if (onReturnClick) {
      onReturnClick();
      handleFilterTable(scacSelected, stSelected, sslSelected, lfdDateSelected, containerFiltered)

      // setLFDInputToToday();
    }
  };

  return (
    <>
      {activated ? (
        <section
          id="returnButton"
          style={{
            display: "grid",
            paddingTop: "10px",
            maxHeight: "20vh",
            paddingBottom: "20px",
          }}
        >
          <div className="col-md-4 col-sm-6 d-flex align-items-start">
            <button
              type="button"
              className="btn btn-primary"
              id="buttonReturnToAvailable"
              onClick={handleReturnClick}
            >
              <BsFillCaretLeftFill
                style={{ marginRight: "30px", color: "#FC4817" }}
              />
              Return to Available Empties
            </button>
          </div>
        </section>
      ) : (
        <section id="fields-details-table">

          <div className="row row-detail-table-fields">
            <div className=" col-6 col-md-6 col-sm-6 col-lg-2 col-xl-2">
              <div className="form-floating mb-3" style={{ margin: "10px" }}>
                <select
                  className="form-select form"
                  id="scacSelect"
                  onChange={(e) => handleFillScacSelected(e.target.value)}
                >
                  {sslList.length > 0 ? (
                    renderOption(scacList, scacSelected)
                  ) : (
                    <option value="">No SCAC available</option>
                  )}
                </select>
                <label htmlFor="scacSelect">SCAC</label>
              </div>
            </div>

            <div className="col-6 col-xs-6 col-sm-6 col-lg-2 col-xl-2">
              <div className="form-floating mb-3" style={{ margin: "10px" }}>
                <select
                  className="form-select form"
                  id="sslSelect"
                  onChange={(e) => handleFillSslSelected(e.target.value)}
                >
                  {sslList.length > 0 ? (
                    renderOption(sslList, sslSelected)
                  ) : (
                    <option value="">No SSL available</option>
                  )}
                </select>

                <label htmlFor="sslSelect">SSL</label>
              </div>
            </div>

            <div className="col-md-6  col-6 col-sm-6 col-lg-2 col-xl-2">
              <div className="form-floating mb-3" style={{ margin: "10px" }}>
                <select
                  className="form-select"
                  id="stSelect"
                  onChange={(e) => handleFillStSelected(e.target.value)}
                >
                  {stList.length > 0 ? (
                    renderOption(stList, stSelected)
                  ) : (
                    <option value="">No SSL available</option>
                  )}
                </select>

                <label htmlFor="stSelect">S/T</label>
              </div>
            </div>
            <div className="col-6 col-xs-6 col-sm-6 col-lg-2 col-xl-2">
              <div className="form-floating mb-3" style={{ margin: "10px" }}>
                <select
                  className="form-select"
                  id="statusSelected"
                  onChange={(e) => setStatusSelected(e.target.value)}
                >
                  {statusList.length > 0 ? (
                    renderOption(statusList, statusSelected)
                  ) : (
                    <option value="">No Status Available</option>
                  )}
                </select>

                <label htmlFor="statusSelect">Status</label>
              </div>
            </div>
            <div className="col-md-6 col-6 col-sm-6 col-lg-2 col-xl-2 ">
              <div
                className="form-floating mb-3"
                id="formDateSpace"
                style={{ margin: "10px", height: "100%" }}
              >
                <label
                  htmlFor="dateInput"
                  className="form-label"
                  style={{
                    paddingTop: "6px",
                    fontSize: "14px",
                    color: "#747679",
                  }}
                >
                  Last free day:
                </label>
                <input
                  type="date"
                  className="form-control input-date"
                  id="dateInput-detail-table-fields"
                  onChange={handleFillLfdDate}
                  style={{ height: "58px" }}
                />
              </div>
            </div>

            <div className="col-md-6 col-6 col-sm-6 col-lg-2 col-xl-2 ">

              <div className="form-floating mb-3"
                id="containerFilter"
                style={{ margin: "10px", height: "100%" }}>
                <label
                  htmlFor="dateInput"
                  className="form-label"
                  style={{
                    paddingTop: "6px",
                    fontSize: "14px",
                    color: "#747679",
                  }}
                >
                  Search Container
                </label>

                <input class="form-control" id="exampleFormControlInput1" onChange={(e) => setContainerFiltered(e.target.value.toUpperCase())} />
              </div>
            </div>
            <div
              className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-12"
              id="columnButton-detail-table-fields"
            >
              <a
                className="btn btn-primary"
                id="sslButton-detail-table-fields"
                href={`/yms-st/streetturn-sslsummary`}
              >
                <FaGlobe style={{ marginRight: "10px" }}></FaGlobe>SSL Summary
              </a>
            </div>
          </div>

        </section>
      )}
    </>
  );
};

export default FieldsRow;
