import axios from "axios";

export const fetchDataFromAPI = async () => {
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
    "Content-Type": "application/json",
  };
  try {
    return axios.get(
      process.env.REACT_APP_DATA_FROM_API,
      { headers: headers }
    );
  } catch (error) {
    throw error;
  }
};

// create StreetTurn function
export const createStreetTurn = async (data) => {
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
    "Content-Type": "application/json",
  };
  const config = {
    headers: headers,
    method: "post",
    url: process.env.REACT_APP_CREATE_STREET_TURN,
    data: data,
  };
  return axios(config);
};
