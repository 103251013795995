import React, { useState, useEffect, useContext } from "react";
import { fetchDataFromAPI } from "../../services/services";
import CargoModel from "../../models/tableModel";
import { StoreContext } from "../../stores/StoreContext";

const useAvailableTableController = () => {
  const {
    handleFillCheckedList,
    checkedList,
    handleSetTableRef,
    handleFillScacList,
    handleFillStList,
    scacSelected,
    tableRefference,
    handleFillGeneralData,
    generalData,
    stSelected,
    handleFillFilteredData,
    filteredData,
    handleFillSslList,
    sslSelected,
    lfdDateSelected,
    prevList,
    handlePrevList,
    handleDeletePrevList,
    handleCleanPrevList,
    containerFiltered,
    setStatusList,
    statusSelected,
  } = useContext(StoreContext);

  //Variables-------------------------------------------------------------------
  const [sorted, setSorted] = useState(false);
  const [show, setShow] = useState(true);
  //Effects---------------------------------------------------------------------

  useEffect(() => {
    fetchCargoData();
    // setLFDInputToToday();
  }, []);
  useEffect(() => {
    handleFilterTable(scacSelected, stSelected, sslSelected, lfdDateSelected, containerFiltered, statusSelected);
    handleCheckAllUpdate(tableRefference);
  }, [scacSelected, stSelected, sslSelected, lfdDateSelected, generalData, containerFiltered, statusSelected]);
  //Functions-------------------------------------------------------------------

  const setLFDInputToToday = () => {
    const today = new Date();
    const inputDate = document.getElementById("dateInput");
    if (inputDate) {
      inputDate.value = today.toISOString().substr(0, 10);
    }
  };

  //Esto pasa al inicio para filtrar la data que viene y al filtrar con cada input
  const handleFilterTable = (
    scacSelected,
    stSelected,
    sslSelected,
    lfdDateSelected,
    containerFiltered,
    statusSelected
  ) => {
    if (containerFiltered === "") {
      const filteredList = generalData.filter(
        (item) =>
          (scacSelected === "ALL" || item.scac === scacSelected) &&
          (stSelected === "ALL" || item.size === stSelected) &&
          (sslSelected === "ALL" || item.ssl === sslSelected) &&
          (statusSelected === "ALL" || item.status === statusSelected) &&
          (!lfdDateSelected || item.lastFreeDay === lfdDateSelected)
      );
      handleFillFilteredData(filteredList);
    }
    else {
      const filteredList = generalData.filter(
        (item) =>
          (scacSelected === "ALL" || item.scac === scacSelected) &&
          (stSelected === "ALL" || item.size === stSelected) &&
          (sslSelected === "ALL" || item.ssl === sslSelected) &&
          (statusSelected === "ALL" || item.status === statusSelected) &&
          (!lfdDateSelected || item.lastFreeDay === lfdDateSelected) &&
          (containerFiltered === "" || item.containerNumber.includes(containerFiltered))
      );
      handleFillFilteredData(filteredList);
    }
    // Your condition to check
    if (
      scacSelected !== "ALL" ||
      stSelected !== "ALL" ||
      sslSelected !== "ALL" ||
      statusSelected !== "ALL"
    ) {
      const checkboxAll = document.getElementById("checkAll");
      if (checkboxAll) {
        checkboxAll.style.display = "none";
      }
    } else {
      const checkboxAll = document.getElementById("checkAll");
      if (checkboxAll) {
        checkboxAll.style.display = "flex";
        checkboxAll.style.alignContent = "center";
      }

    }
    setTimeout(() => {
      handleTableUpdate(tableRefference);
    }, 1);
  };

  //Poner el CheckAll en false cuando se filtra
  const handleCheckAllUpdate = (tableRef) => {
    const checkboxAll = document.getElementById("checkAll");

    if (checkboxAll) {
      checkboxAll.checked = false;
    }
  };
  const handleTableUpdate = (tableRef) => {
    if (tableRef && tableRef.current) {
      const table = tableRef.current;
      const rows = table.getElementsByTagName("tr");

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const cells = row.getElementsByTagName("td");

        if (cells.length > 0) {
          const cell0 = cells[0];
          const cell4 = cells[4];
          const cellText4 = cell4.textContent;
          const checkbox = cell0.querySelector('input[type="checkbox"]');
          let isInCheckedGroup = false;
          checkedList.forEach((item) => {
            if (item.checkedgroup.includes(cellText4)) {
              isInCheckedGroup = true;
            }
          });
          if (checkbox) {
            checkbox.checked = isInCheckedGroup;
          }

        }
      }
    }
  };

  const handleSetTableRefference = (tableRef) => {
    handleSetTableRef(tableRef);
  };
  const handleCheck = (checked, item, inReviewState, tableRef) => {

    handleFillCheckedList((prevCheckedList) => {
      const updatedList = [...prevCheckedList];

      const existingItemIndex = updatedList.findIndex(
        (entry) =>
          entry.scac === item.scac &&
          entry.ssl === item.ssl &&
          entry.size === item.size
      );
      if (checked) {
        handlePrevList(item);
        if (existingItemIndex !== -1) {
          const existingItem = updatedList[existingItemIndex];
          if (!existingItem.checkedgroup.includes(item.containerNumber)) {
            existingItem.total += 1;
            existingItem.checkedgroup.push(item.containerNumber);
          }
        } else {
          const newItem = {
            scac: item.scac,
            ssl: item.ssl,
            size: item.size,
            total: 1,
            checkedgroup: [item.containerNumber],
          };
          updatedList.push(newItem);
        }

      } else {
        handleDeletePrevList(item);
        if (existingItemIndex !== -1) {
          const existingItem = updatedList[existingItemIndex];
          if (existingItem.checkedgroup.includes(item.containerNumber)) {
            if (existingItem.total === 1) {
              updatedList.splice(existingItemIndex, 1);
            } else {
              existingItem.total -= 1;
              existingItem.checkedgroup = existingItem.checkedgroup.filter(
                (num) => num !== item.containerNumber
              );
            }
          }
        }
      }
      handleFillCheckedList(updatedList);
      return updatedList;
    });
    if (inReviewState) {
      handleReview(tableRef);
    }
  };

  const handleAllPrev = async (tableRef, checked) => {
    if (checked) {
      const table = tableRef.current;
      if (table) {
        const rows = table.getElementsByTagName("tr");
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const cells = row.getElementsByTagName("td");
          if (cells.length > 0) {
            const cell0 = cells[0];
            const cel7 = cells[7];

            if (cel7.textContent !== "External") {
              const checkbox = cell0.querySelector('input[type="checkbox"]');
              if (checkbox) {
                checkbox.checked = true;
              }
            }
          }
        }
      }
      filteredData.forEach((item) => {
        if (item.status !== "External")
          handleCheck(true, item);
      });
    } else {
      handleCleanPrevList();
      handleFillCheckedList([]);
      const table = tableRef.current;
      if (table) {
        const rows = table.getElementsByTagName("tr");
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const cells = row.getElementsByTagName("td");
          if (cells.length > 0) {
            const cell0 = cells[0];
            const cel7 = cells[7];
            if (cel7.textContent !== "External") {
              const checkbox = cell0.querySelector('input[type="checkbox"]');
              if (checkbox) {
                checkbox.checked = false;
              }
            }
          }
        }
      }
    }
  };

  // Remove the unused fetchEveryMiinute function
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchCargoData();
  //   }, 20000);
  //   return () => clearInterval(interval); // Clean up the interval on component unmount
  // });


  const fetchCargoData = async () => {
    setShow(true);
    try {
      const response = await fetchDataFromAPI();
      if (response && response.data && response.data.data) {
        const cargoData = response.data.data;
        const cargoObjects = Array.isArray(cargoData)
          ? cargoData.map((item) => new CargoModel(item))
          : [];
        handleFillGeneralData(cargoObjects);
        handleFillFilteredData(cargoObjects);

        const scacList = [
          "ALL",
          ...new Set(cargoObjects.map((item) => item.scac)),
        ];
        const stList = [
          "ALL",
          ...new Set(cargoObjects.map((item) => item.size)),
        ];
        const sslList = [
          "ALL",
          ...new Set(cargoObjects.map((item) => item.ssl)),
        ];
        const statusList = [
          "ALL",
          ...new Set(cargoObjects.map((item) => item.status)),
        ];
        handleFillScacList(scacList);
        handleFillStList(stList);
        handleFillSslList(sslList);
        setStatusList(statusList);
        setShow(false);
        handleTableUpdate(tableRefference);
      } else {
        console.error(
          "Error fetching cargo data: Response structure is unexpected"
        );
      }
    } catch (error) {
      console.error("Error fetching cargo data:", error);
    }
  };


  const handleReview = () => {
    handleFillFilteredData(prevList);
  };



  const handleReturn = (tableRef) => {
    const table = tableRef.current;
    if (table) {
      const rows = table.getElementsByTagName("tr");
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        row.classList.remove("hidden");
      }
    }
  };


  const handleSortColumn = () => {

    if (sorted === false) {
      const newData = [...filteredData];
      newData.sort((a, b) => a.ssl.localeCompare(b.ssl));
      handleFillFilteredData(newData);
      setSorted(true);
    } else {
      const newData = [...filteredData];
      newData.sort((a, b) => b.ssl.localeCompare(a.ssl));
      handleFillFilteredData(newData);
      setSorted(false);
    }
  }
  return {
    handleAllPrev,
    handleSetTableRefference,
    handleCheck,
    handleReturn,
    handleTableUpdate,
    handleSortColumn,
    show,
    handleFilterTable,
    fetchCargoData,
  };
};

export default useAvailableTableController;
