import React, { useState, useEffect, useContext } from "react";
import { fetchDataFromAPI, createStreetTurn } from "../../services/services";
import CargoModel from "../../models/tableModel";
import { StoreContext } from "../../stores/StoreContext";

const useSelectedTableController = () => {
  const {
    handleFillCheckedList,
    checkedList,
    handleFillFilteredData,
    prevList,
    handleDeletePrevItemByIcon,
    user,
  } = useContext(StoreContext);

  //Variables-------------------------------------------------------------------
  const [checkedCount, setCheckedCount] = useState(0);
  const [sending, setSending] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  //Effects---------------------------------------------------------------------
  useEffect(() => {
    let count = 0;
    checkedList.forEach((element) => {
      count += element.total;
    });
    setCheckedCount(count);
  }, [checkedList]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        handleSetShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  //Functions-------------------------------------------------------------------

  const handleDelete = (tableRef, index, inReviewState) => {
    const table = tableRef.current;
    if (table) {
      const rows = table.getElementsByTagName("tr");
      const item = checkedList[index];
      handleDeletePrevItemByIcon(item);
      if (item) {
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          const cells = row.getElementsByTagName("td");
          if (cells.length > 0) {
            const cell0 = cells[0];
            const cell4 = cells[4];
            const cellText4 = cell4.textContent;
            const checkbox = cell0.querySelector('input[type="checkbox"]');
            if (checkbox && item.checkedgroup.includes(cellText4)) {
              checkbox.checked = false;
            }
          }
        }
        const updatedList = [...checkedList];
        updatedList.splice(index, 1);

        handleFillCheckedList(updatedList);
      }
    }
    if (inReviewState) {
      handleReview(tableRef);
    }
  };

  const handleSend = () => {
    setSending(true);
    let data = {
      userName: user.name,
      startDate: new Date().toISOString(),
      scacCodeCarrier: user.scac,
      empties: prevList,
    };

    createStreetTurn(data)
      .then((response) => {
        handleSetToastMessage(response.data.Message);
        handleSetToastType("success");
        handleSetShowToast(true);
        setSending(false);
        // redirect to /yms-sreetturnspending
        window.location.href = "/yms-st/sreetturnspending";
      })
      .catch((error) => {
        handleSetToastMessage(error.response.data.Message);
        handleSetToastType("error");
        handleSetShowToast(true);
        setSending(false);
      });
  };

  const handleReview = (tableRef) => {
    handleFillFilteredData(prevList);
  };

  const handleSetShowToast = (showToast) => {
    setShowToast(showToast);
  };

  const handleSetToastMessage = (toastMessage) => {
    setToastMessage(toastMessage);
  };

  const handleSetToastType = (toastType) => {
    setToastType(toastType);
  };

  return {
    checkedCount,
    showToast,
    toastMessage,
    toastType,
    handleDelete,
    handleReview,
    handleSend,
    sending,
  };
};

export default useSelectedTableController;
