export const convertUtcToMMDD = (utcDate) => {
  const dateUtc = new Date(utcDate);
  const offsetMinutes = dateUtc.getTimezoneOffset();
  const dateLocal = new Date(dateUtc.getTime() - offsetMinutes * 60000);
  const month = (dateLocal.getMonth() + 1).toString().padStart(2, '0');
  const day = dateLocal.getDate().toString().padStart(2, '0');

  return `${month}/${day}`;
};

export const convertToMMDD = (date) => {
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + 1); // Add 1 day
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  return `${month}/${day}`;
}
