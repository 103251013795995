import React, { useState } from "react";

const ToasterNotification = ({ showToast, toastMessage, toastType }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.value = toastMessage;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
  };

  // Determine the toast class based on the toastType
  const toastClass = (() => {
    switch (toastType) {
      case "success":
        return "text-bg-success";
      case "error":
        return "text-bg-danger";
      case "info":
        return "text-bg-info";
      case "warning":
        return "text-bg-warning";
      default:
        return "text-bg-info";
    }
  })();

  return (
    <div class="toast-container position-fixed bottom-0 start-0 p-3">
      <div
        className={`toast text-align-start ${
          showToast ? "show" : ""
        } ${toastClass}`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ textAlign: "left" }}
      >
        <div className="toast-body">
          {toastMessage}
          {toastType !== "success" && (
            <div class="mt-2 pt-2 border-top">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                onClick={copyToClipboard}
              >
                Copy text
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToasterNotification;
