import React, { useContext, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { StoreContext } from "../../stores/StoreContext";
import "./optionTableModal.css";
import { BsPaperclip } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";

const OptionsTableModal = () => {
  let { shownModal, handleFillShownModal, modalData } =
    useContext(StoreContext);

  const handleCloseModal = () => {
    handleFillShownModal(false);
  };
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("Attach Docs");

  const handleFileChange = () => {
    const selectedFileName =
      fileInputRef.current.files[0]?.name || "Attach Docs";
    setFileName(selectedFileName);
  };
  return (
    <>
      <div
        className={`offcanvas offcanvas-end ${shownModal ? "show" : ""}`}
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="staticBackdropLabel">
            Edit
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={handleCloseModal}
          ></button>
        </div>

        <div className="offcanvas-body">
          <div className="container-fluid">
            <div className="bs-row">
              <div className="col-12" style={{ paddingBottom: "3%" }}></div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Scac"
                  />
                  <label for="floatingInput">Scac Code</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="SSL"
                  />
                  <label for="floatingInput">SSL</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="S/T"
                  />
                  <label for="floatingInput">S/T</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Con/Trl Num"
                  />
                  <label for="floatingInput">Cont/Trl Num</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Chassis N"
                  />
                  <label for="floatingInput">Chassis N</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="LFD"
                  />
                  <label for="floatingInput">LFD</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Status"
                  />
                  <label for="floatingInput">Status</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Arrival"
                  />
                  <label for="floatingInput">Arrival</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Location"
                  />
                  <label for="floatingInput">Location</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Spot"
                  />
                  <label for="floatingInput">Spot</label>
                </div>
              </div>
            </div>
            <hr style={{ paddingTop: "5%" }}></hr>
            <div className="row">
              <div className="col-6">
                <label className="btn btn-primary attach">
                  <span id="customFileLabel">
                    <BsPaperclip style={{ marginRight: "10%" }}></BsPaperclip>
                    {fileName}
                  </span>
                  <input
                    type="file"
                    id="formFile"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              <div className="col-6">
                <div className="btn btn-success" id="btnModal">
                  <AiOutlineSave style={{ marginRight: "10%" }} />
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptionsTableModal;
