import React, { useState, createContext, useEffect } from "react";
import Cookies from 'universal-cookie';
export const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {
  const [generalData, setGeneralData] = useState(null || []);
  const [filteredData, setFilteredData] = useState(null);
  const [checkedList, setCheckedList] = useState(null || []);
  const [tableRefference, setTableRef] = useState(null);
  const [scacList, setScacList] = useState(null || []);
  const [stList, setStList] = useState(null || []);
  const [sslList, setSslList] = useState(null || []);
  const [scacSelected, setScacSelected] = useState("ALL");
  const [stSelected, setStSelected] = useState("ALL");
  const [sslSelected, setSslSelected] = useState("ALL");
  const [shownModal, setShownModal] = useState(false);
  const [lfdDate, setLfdDate] = useState(new Date());
  const [lfdDateSelected, setLfdDateSelected] = useState(null || "");
  const [modalData, setModalData] = useState(null || []);
  const [prevList, setPrevList] = useState([]);
  const [containerFiltered, setContainerFiltered] = useState("");
  const [statusList, setStatusList] = useState(null || []);
  const [statusSelected, setStatusSelected] = useState("ALL");
  const [user, setUser] = useState(null || []);
  const cookies = new Cookies(null, { path: '/' });
  const [canReview, setCanReview] = useState(false);
  //USE EFFECT SET USER
  useEffect(() => {
    if (cookies.get("sessionCosmos")) {
      setUser(cookies.get("sessionCosmos"))
    } else {
      cookies.addChangeListener((session) => {
        if (session.name === "sessionCosmos") {
          setUser(session.value)
        }
      })
    }
  }, []);
  //GENERAL DATA
  const handleFillGeneralData = (newData) => {
    setGeneralData(newData);
  };

  //FILTERED DATA
  const handleFillFilteredData = (newData) => {
    setFilteredData(newData);
  };

  //CHECKED LIST
  const handleFillCheckedList = (newList) => {
    setCheckedList(newList);
  };

  //TABLE REFERENCE
  const handleSetTableRef = (tableRef) => {
    setTableRef(tableRef);
  };

  //SCAC LIST
  const handleFillScacList = (newList) => {
    setScacList(newList);
  };

  //ST LIST
  const handleFillStList = (newList) => {
    setStList(newList);
  };

  //SSL LIST
  const handleFillSslList = (newList) => {
    setSslList(newList);
  };

  //SCAC SELECTED
  const handleFillScacSelected = (newScac) => {
    setScacSelected(newScac);
  };

  //ST SELECTED
  const handleFillStSelected = (newSt) => {
    setStSelected(newSt);
  };

  //SSL SELECTED
  const handleFillSslSelected = (newSsl) => {
    setSslSelected(newSsl);
  };

  //SHOWN MODAL
  const handleFillShownModal = (showModal, item) => {
    setShownModal(showModal);
    setModalData(item);
  };

  //LFD DATE
  const handleFillLfdDate = (newDate) => {
    const lfdInputDate = document.getElementById("dateInput");
    const inputValue = lfdInputDate.value;
    setLfdDate(inputValue);

    if (inputValue === null || inputValue === "") {
      setLfdDateSelected("");
    } else {
      const inputDate = new Date(inputValue);
      const options = { month: "2-digit", day: "2-digit", timeZone: "UTC" };
      const formattedDate = inputDate.toLocaleDateString("en-US", options);
      setLfdDateSelected(formattedDate);
    }
  };

  const handlePrevList = (newItem) => {
    const isDuplicate = prevList.some(
      (item) => item.containerNumber === newItem.containerNumber
    );

    if (!isDuplicate) {
      setPrevList((prevList) => [...prevList, newItem]);
    }
  };

  const handleDeletePrevList = (newItem) => {
    setPrevList((prevList) => prevList.filter((item) => item !== newItem));
  };

  const handleDeletePrevItemByIcon = (checkedgroup) => {
    const checkedGroupItems = checkedgroup.checkedgroup;

    const updatedPrevList = prevList.filter((prevItem) => {
      return !checkedGroupItems.includes(prevItem.containerNumber);
    });
    setPrevList(updatedPrevList);
  };

  const handleCleanPrevList = () => {
    setPrevList([]);
  };

  useEffect(() => {
    if(checkedList.length === 0){
      setCanReview(false)
    }else{
      setCanReview(true)
    }
  },[checkedList])


  return (
    <StoreContext.Provider
      value={{
        checkedList,
        handleFillCheckedList,
        handleSetTableRef,
        tableRefference,
        handleFillScacList,
        scacList,
        handleFillStList,
        stList,
        scacSelected,
        handleFillScacSelected,
        stSelected,
        handleFillStSelected,
        generalData,
        handleFillGeneralData,
        filteredData,
        handleFillFilteredData,
        shownModal,
        handleFillShownModal,
        sslSelected,
        handleFillSslSelected,
        sslList,
        handleFillSslList,
        lfdDate,
        handleFillLfdDate,
        lfdDateSelected,
        modalData,
        prevList,
        handlePrevList,
        handleDeletePrevList,
        handleCleanPrevList,
        handleDeletePrevItemByIcon,
        setContainerFiltered,
        containerFiltered,
        statusList,
        setStatusList,
        statusSelected,
        setStatusSelected,
        user,
        setUser,
        canReview,
        
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
